import { useState } from "react";

const PlanCard = ({
    plan,
    billPlan,
    onClick,
}: {
    plan: any;
    billPlan: string;
    onClick: (e: any) => void;
}) => {
    const [showAllFeatures, setShowAllFeatures] = useState(false);

    const toggleShowMore = () => {
        setShowAllFeatures(!showAllFeatures);
    };

    return (
        <div
            className={`relative col-span-1 h-auto p-8 bg-gray-900 rounded-xl w-full text-white border-4 border-white shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(0,0,0,0.15)]`}
        >
            <h1 className={`text-white font-semibold text-2xl`}>{plan.name}</h1>
            <div className="flex-shrink-0 mt-1">
                {
                    (plan.price as any)[billPlan] === 0 ? <>
                        <span className={`text-xl font-medium tracking-tight`}>
                            {`₦ ${(plan.price as any)['annually'].toLocaleString()}`}
                        </span>
                        {
                            plan.tenur !== false && <span className="text-gray-400">
                                /year
                            </span>
                        }
                    </>
                        : <>
                            <span className={`text-xl font-medium tracking-tight`}>
                                {`₦ ${(plan.price as any)[billPlan].toLocaleString()}`}
                            </span>
                            {
                                plan.tenur !== false && <span className="text-gray-400">
                                    {billPlan === "monthly" ? " /monthly" : " /year"}
                                </span>
                            }
                        </>
                }

            </div>
            <hr className="mt-4 border-1" />
            <div className="pt-8">
                <div
                    className={`grid overflow-hidden transition-all duration-1000 ease-in-out text-light ${!showAllFeatures ? "grid-rows-[0fr]" : "grid-rows-[1fr]"
                        }`}
                >
                    <div className="overflow-hidden row-span-1">
                        <div className="min-h-32 text-white">
                            {plan.features
                                .slice(
                                    0,
                                    showAllFeatures
                                        ? plan.features.length
                                        : plan.features.length > 5
                                            ? 3
                                            : 5
                                )
                                .map(
                                    (feature: string, featureIndex: number) => (
                                        <p
                                            key={featureIndex}
                                            className="font-normal text-gray-400 text-left pt-2"
                                        >
                                            <span className="material-icons text-xs align-middle">
                                                done
                                            </span>
                                            <span className="pl-2 text-sm">
                                                <span className="!text-white">
                                                    {feature}
                                                </span>
                                            </span>
                                        </p>
                                    )
                                )}
                        </div>
                    </div>
                    <div className="overflow-hidden row-span-1 transition-all duration-1000 ease-in-out">
                        {!showAllFeatures && (
                            <div>
                                {plan.features
                                    .slice(0, plan.features.length > 5 ? 4 : 5)
                                    .map(
                                        (
                                            feature: string,
                                            featureIndex: number
                                        ) => (
                                            <p
                                                key={featureIndex}
                                                className="font-normal text-gray-400 text-left pt-2"
                                            >
                                                <span className="material-icons text-xs align-middle">
                                                    done
                                                </span>
                                                <span className="pl-2">
                                                    <span className="text-white">
                                                        {feature}
                                                    </span>
                                                </span>
                                            </p>
                                        )
                                    )}
                            </div>
                        )}
                        {plan.features.length > 5 && (
                            <button
                                className="font-normal w-full text-gray-400 h-8 text-center pt-2"
                                onClick={toggleShowMore}
                            >
                                <span className="text-sm">
                                    <span className="text-white">
                                        Show{" "}
                                        {!showAllFeatures ? "more" : "less"}
                                    </span>
                                </span>
                                <span
                                    className="material-icons pl-1 text-md align-middle transition-transform transform"
                                    style={{
                                        transform: showAllFeatures
                                            ? "rotate(180deg)"
                                            : "rotate(0deg)",
                                    }}
                                >
                                    expand_more
                                </span>
                            </button>
                        )}
                    </div>
                </div>
                {(plan.price as any)[billPlan] > 0 && (
                    <button
                        className={`flex mt-8 justify-center items-center ${plan.buttonColor} rounded-xl py-4 w-full px-4 text-center text-white`}
                        onClick={() => onClick(plan)}
                    >
                        <span className="font-medium">Choose Plan</span>
                        <span className="pl-2 material-icons align-middle text-sm">
                            east
                        </span>
                    </button>
                )}
            </div>
            {plan.popular && (
                <div className="absolute top-4 right-4">
                    <p className="bg-blue-700 font-semibold px-4 py-1 rounded-full uppercase text-xs">
                        Popular
                    </p>
                </div>
            )}
        </div>
    );
};

export default PlanCard;
