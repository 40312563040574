import emailjs from "@emailjs/browser";
import { FlutterWaveButton, closePaymentModal } from "flutterwave-react-v3";
import { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
    A11y,
    Autoplay,
    EffectCoverflow,
    EffectFade,
    Navigation,
    Pagination,
    Scrollbar,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ChipComponent from "./chip-component";
import InfiniteScrollComponent from "./infinite-scroll";
import PlanCard from "./plan-card";

const images = [
    "dreezer",
    "insta",
    "music",
    "soundcloud",
    "spotify",
    "tiktok",
    "youtube",
];

const App = () => {
    useEffect(() => emailjs.init("99HM-wsM6blfmofDr"), []);
    const { width, height } = useWindowSize();
    const [billPlan, setBillPlan] = useState("monthly");
    const [showModal, setShowModal] = useState(false);
    const [isSuccessful, setIsSuccess] = useState(false);
    const [amount, setAmount] = useState(0);
    const [desc, setDesc] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);

    useEffect(() => {
        if (!showModal) {
            setAmount(0);
            setEmail("");
            setName("");
            setPhone("");
            setIsValidEmail(true);
        }
    }, [showModal]);

    const handleSubmit = async () => {
        const serviceID = "default_service";
        const templateID = "template_8zgjs58";
        const emailData = {
            email: email,
            subject: desc,
            message: desc,
            mobile: phone,
            amount: amount.toLocaleString(),
            name: name,
        };

        try {
            await emailjs.send(serviceID, templateID, { ...emailData });
        } catch (error) {
            alert("Email failed to deliver");
        }
    };

    const config = {
        public_key: process.env.REACT_APP_API_KEY || "",
        tx_ref: Date.now() as any,
        amount,
        currency: "NGN",
        country: "Nigeria",
        payment_options: "card,mobilemoney,ussd",
        description: desc,
        email,
        phone_number: phone,
        name: name,
        customer: {
            email,
            phone_number: phone,
            name: name,
            description: desc,
        },
        customizations: {
            title: "IYK Media",
            description: desc,
            logo: "https://res.cloudinary.com/sulaimon/image/upload/v1703595976/spb9it7dzvjpnqrlbc7k.jpg",
        },
    };

    const fwConfig = {
        ...config,
        text: "Pay with Flutterwave!",
        callback: (response: any) => {
            handleSubmit();
            setIsSuccess(true);
            closePaymentModal(); // this will close the modal programmatically
        },
        onClose: () => { },
    };

    const validateEmail = (value: string) => {
        // Regular expression for a simple email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
    };

    const handleEmailChange = (e: { target: { value: string } }) => {
        const newValue = e.target.value;
        validateEmail(newValue) ? setEmail(newValue) : setEmail("");
        setIsValidEmail(validateEmail(newValue));
    };

    function isComponentPropsComplete(props: {
        amount: any;
        customer: any;
        public_key: any;
    }) {
        // Add your logic to check if componentProps is complete
        // For example, you can check if all required fields are present
        return (
            props &&
            props.amount &&
            props.customer &&
            props.customer.name &&
            props.customer.phone_number &&
            props.public_key
        );
    }

    const plans = [
        {
            name: "MUSIC DISTRIBUTION & PROMOTION",
            price: {
                monthly: 20000,
                annually: 50000000,
            },
            features: [
                "Music Distribution to 150 Digital Stores",
                "Song Pitching",
                "Album Cover Graphics",
                "Copyright Ownership",
                "Monthly Stats Update",
            ],
            color: "shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(0,0,0,0.15)] w-full rounded-xl border-4 border-white",
            buttonColor: "bg-blue-600",
        },
        {
            name: "GOLD PLAN DISTRIBUTION & PROMOTION",
            price: {
                monthly: 2000000,
                annually: 2000000,
            },
            tenur: false,
            features: [
                "Song Distribution to 150 Digital stores",
                "Song Licensing",
                "Song Pitching to 20 Countries",
                "Song Publishing Right",
                "Itunez Promotion",
                "Audiomack Promotion",
                "Spotify Promotion",
                "Google ID & Bio",
                "Album Cover Graphics",
                "Monthly Stats Update",
            ],
            color: "bg-gray-900 rounded-xl w-full text-white border-4 border-white shadow-xl",
            buttonColor: "bg-blue-600",
            popular: true,
        },
        {
            name: "ARTISTE PLAN DISTRIBUTION & PROMOTION",
            price: {
                monthly: 1000000,
                annually: 1000000,
            },
            tenur: false,
            features: [
                "Song Distribution to Digital 150 stores",
                "Song Pitching to 15 Countries",
                "Song Publishing Right",
                "Audiomack Promotion",
                "Spotify Promotion",
                "Album Cover Graphics",
                "Monthly Stats Update",
            ],
            color: "shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(0,0,0,0.15)] w-full rounded-xl border-4 border-white",
            buttonColor: "bg-blue-600",
        },
        {
            name: "PREMIUM PLAN DISTRIBUTION & PROMOTION",
            price: {
                monthly: 3000000,
                annually: 3000000,
            },
            tenur: false,
            features: [
                "Music Distribution to 200 Digital Stores",
                "Music Licensing",
                "Song Pitching",
                "Album Cover Graphics",
                "10 Major Spotify Playlist",
                "Song Monetization",
                "Verified Spotify Account",
                "Verified ITunes Account",
                "100 -  Audiomack Playlist",
                "1k Audiomack Followers",
                "50k Audiomack Plays",
                "Music Watermark Code",
                "Google ID and Bio",
                "Copyright Ownership",
                "Monthly Stats Update",
            ],
            color: "shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(0,0,0,0.15)] w-full rounded-xl border-4 border-white",
            buttonColor: "bg-blue-600",
        },
        {
            name: "MUSIC LICENSING & COPYRIGHT OWNERSHIP",
            price: {
                monthly: 0,
                annually: 250000,
            },
            features: [
                "Piracy Security",
                "Publishing Permit",
                "Royalty claim",
                "Artiste Rights Security",
                "Customized Watermark Code",
            ],
            color: "shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(0,0,0,0.15)] w-full rounded-xl border-4 border-white",
            buttonColor: "bg-blue-600",
        },
    ];

    const toggleBillPlan = () => {
        setBillPlan(billPlan === "monthly" ? "annually" : "monthly");
    };

    const data = [
        "Music Distribution",
        "Music Promotion",
        "Music Licensing",
        "Music Publishing",
        "Music Playlisting",
        "Music Pitching",
        "Spotify Promotion",
        "Tunes Promotion",
        "Audiomack Promotion",
        "Talent Management",
        "Talent Branding",
    ];
    // https://aqtent.com/wp-content/uploads/2022/07/WhatsApp-Image-2022-09-11-at-9.08.08-AM.jpeg
    return (
        <>
            <div className="md:min-h-screen min-h-[80vh] h-full relative md:h-screen">
                <div className="h-full relative w-full md:flex items-center">
                    <img
                        src="https://aqtent.com/wp-content/uploads/2022/07/WhatsApp-Image-2022-09-11-at-9.08.08-AM.jpeg"
                        alt="music brand"
                        className="bg-no-repeat bg-contain relative w-full min-h-[80vh] h-full"
                    />
                </div>
                <header className="h-full bg-[#161415] bg-opacity-80 md:bg-opacity-70 absolute top-0 left-0 right-0 z-50 md:h-screen bg-no-repeat bg-cover overflow-hidden bg-center flex flex-col">
                    <div className="flex-1 pb-4 flex flex-col items-center justify-center">
                        <div className="text-center text-white">
                            <img
                                src="/brand.png"
                                alt="music brand"
                                className="h-80 object-none mx-auto"
                            />
                            <h1 className="text-4xl mb-4">
                                Welcome to IYK Media
                            </h1>
                            <p className="mb-8">We Specialize on</p>
                            <div className="flex justify-between mt-10 w-[816px] text-light text-[13px] leading-[100%]">
                                <InfiniteScrollComponent
                                    direction="left"
                                    speed="slow"
                                >
                                    {data.map((e) => (
                                        <ChipComponent title={e} key={e} />
                                    ))}
                                </InfiniteScrollComponent>
                            </div>
                        </div>
                    </div>
                </header>
            </div>

            <div className="container w- mx-auto antialiased text-gray-900 bg-white pb-8">
                {/* Main content */}
                <main className="mx-4 my-16">
                    <div className="text-center">
                        <h1 className="mb-4 text-2xl font-normal md:text-3xl lg:text-4xl">
                            Our <span className="font-semibold">plans</span> for
                            your{" "}
                            <span className="font-semibold">strategies</span>
                        </h1>
                        <p className="text-sm font-normal text-gray-400">
                            See below our main plans for your distributions and
                            promotions
                        </p>
                    </div>

                    {/* Plan switch */}
                    <div className="flex items-center justify-center mt-10 py-2 space-x-4">
                        <span className="text-base font-medium">
                            Bill Monthly
                        </span>
                        <button
                            className="relative rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            data-x-cloak
                            onClick={toggleBillPlan}
                        >
                            <div className="w-16 h-8 transition bg-indigo-500 rounded-full shadow-md outline-none"></div>
                            <div
                                className={`absolute inline-flex items-center justify-center w-6 h-6 transition-all duration-200 ease-in-out transform bg-white rounded-full shadow-sm top-1 left-1 ${billPlan === "monthly"
                                        ? "translate-x-0"
                                        : "translate-x-8"
                                    }`}
                            ></div>
                        </button>
                        <span className="text-base font-medium">
                            Bill Annually
                        </span>
                    </div>

                    {/* Plans */}
                    {/* <div className="pt-24 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 container gap-8 justify-center">
                        {plans.map((plan, index) => (
                            <div key={index}>
                                <PlanCard
                                    plan={plan}
                                    billPlan={billPlan}
                                    onClick={(e) => {
                                        setShowModal(true);
                                        setAmount((e.price as any)[billPlan]);
                                        setDesc(e.name);
                                    }}
                                />
                            </div>
                        ))}
                    </div> */}
                    <Swiper
                        effect={"coverflow"}
                        grabCursor={true}
                        centeredSlides={false}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            480: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                            640: {
                                slidesPerView: 3,
                                spaceBetween: 40,
                            },
                        }}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: false,
                        }}
                        loop={true}
                        pagination={true}
                        modules={[EffectCoverflow, Pagination]}
                    >
                        {plans.map((plan, index) => (
                            <SwiperSlide key={index}>
                                <div className="py-20 bg-white z-50 opacity-100">
                                    <PlanCard
                                        plan={plan}
                                        billPlan={billPlan}
                                        onClick={(e) => {
                                            setShowModal(true);
                                            setAmount(
                                                (e.price as any)[billPlan]
                                            );
                                            setDesc(e.name);
                                        }}
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </main>
            </div>

            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden bg-black bg-opacity-70 z-50 overflow-y-auto fixed inset-0 outline-none focus:outline-none">
                        {isSuccessful ? (
                            <div className="bg-white rounded-xl p-6  md:mx-auto">
                                <Confetti width={width} height={height} />
                                <svg
                                    viewBox="0 0 24 24"
                                    className="text-green-600 w-16 h-16 mx-auto my-6"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
                                    ></path>
                                </svg>
                                <div className="text-center">
                                    <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
                                        Payment Successful!
                                    </h3>
                                    <p className="text-gray-600 my-4">
                                        Thank you for completing your secure
                                        online payment. Our team will get back
                                        to you via email shortly.
                                    </p>
                                    <p> Have a great day! </p>
                                </div>
                                <div className="mt-4">
                                    <button
                                        className="text-red-500 bg-red-200 rounded-lg background-transparent font-normal uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => {
                                            setShowModal(false);
                                            setIsSuccess(false);
                                        }}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="relative w-full my-6">
                                {/*content*/}
                                <div className="border-0 mx-auto md:w-1/2 xl:w-1/3 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                        <h3 className="text-3xl font-semibold">
                                            Enter Details
                                        </h3>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                            onClick={() => setShowModal(false)}
                                        >
                                            <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                ×
                                            </span>
                                        </button>
                                    </div>
                                    {/*body*/}
                                    <div className="relative p-6 flex-auto">
                                        <form>
                                            <div className="relative w-full mb-3">
                                                <label className="block text-gray-700 mb-1">
                                                    Name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="name"
                                                    onChange={(e) =>
                                                        setName(e.target.value)
                                                    }
                                                    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                                    style={{
                                                        transition:
                                                            "all .15s ease",
                                                    }}
                                                    placeholder="Enter your name"
                                                />
                                            </div>
                                            <div className="relative w-full mb-3">
                                                <label className="block text-gray-700 mb-1">
                                                    Amount
                                                </label>
                                                <input
                                                    type="text"
                                                    id="name"
                                                    className="border-0 px-3 py-3 text-gray-400 bg-white rounded text-sm shadow w-full"
                                                    disabled
                                                    value={amount.toLocaleString()}
                                                />
                                            </div>
                                            <div className="relative w-full mb-3">
                                                <label className="block text-gray-700 mb-1">
                                                    Email
                                                </label>
                                                <input
                                                    type="email"
                                                    id="email"
                                                    onChange={handleEmailChange}
                                                    className={`px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ${isValidEmail
                                                            ? ""
                                                            : "border-2 border-red-500 !ring-0"
                                                        }`}
                                                    style={{
                                                        transition:
                                                            "all .15s ease",
                                                    }}
                                                    placeholder="Enter your email"
                                                />
                                            </div>
                                            <div className="relative w-full mb-3">
                                                <label className="block text-gray-700 mb-1">
                                                    Phone Number
                                                </label>
                                                <input
                                                    type="tel"
                                                    id="phone"
                                                    onChange={(e) =>
                                                        setPhone(e.target.value)
                                                    }
                                                    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                                    style={{
                                                        transition:
                                                            "all .15s ease",
                                                    }}
                                                    placeholder="Enter your mobile number"
                                                />
                                            </div>
                                        </form>
                                    </div>
                                    {/*footer*/}
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => setShowModal(false)}
                                        >
                                            Close
                                        </button>
                                        {isComponentPropsComplete(config) && (
                                            <FlutterWaveButton
                                                {...fwConfig}
                                                className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
            <div className="w-full bg-[#1E1E1E] px-4 py-10  flex flex-wrap items-center justify-center text-red-600">
                <div className="w-full text-center text-white md:mt-8 mb-14 md:mb-0 text-lg font-medium">
                    Distribute your music to all major music and streaming
                    platforms.
                </div>
                <button className="arrow-left arrow !hidden md:!block">
                    <svg
                        width="45"
                        height="45"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        color="#f3f3f3"
                        className="transform rotate-180"
                    >
                        <g clipPath="url(#clip0_640_2044)">
                            <path
                                d="M10 6L8.59003 7.41L13.17 12L8.59003 16.59L10 18L16 12L10 6Z"
                                fill="currentColor"
                            ></path>
                        </g>
                        <defs>
                            <clipPath id="clip0_640_2044">
                                <rect
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                ></rect>
                            </clipPath>
                        </defs>
                    </svg>
                </button>
                <div className="w-full md:w-2/3 md:p-10">
                    <Swiper
                        spaceBetween={50}
                        modules={[
                            Navigation,
                            Scrollbar,
                            A11y,
                            EffectFade,
                            Autoplay,
                        ]}
                        breakpoints={{
                            320: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            480: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                            640: {
                                slidesPerView: 3,
                                spaceBetween: 40,
                            },
                        }}
                        autoplay={{
                            delay: 1500,
                        }}
                        loop={true}
                        navigation={{
                            nextEl: ".arrow-right",
                            prevEl: ".arrow-left",
                        }}
                        pagination={{
                            clickable: true,
                            dynamicBullets: true,
                        }}
                    >
                        <div
                            slot="container-start"
                            className="parallax-bg bg-[#1E1E1E]"
                            data-swiper-parallax="-23%"
                        ></div>
                        {images.map((ele) => (
                            <SwiperSlide key={ele}>
                                <img
                                    src={`/${ele}.png`}
                                    alt="music logo"
                                    className="mx-auto w-[100px] h-[50px] object-contain"
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <button className="arrow-right arrow !hidden md:!block">
                    <svg
                        width="45"
                        height="45"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        color="#f3f3f3"
                    >
                        <g clipPath="url(#clip0_640_2044)">
                            <path
                                d="M10 6L8.59003 7.41L13.17 12L8.59003 16.59L10 18L16 12L10 6Z"
                                fill="currentColor"
                            ></path>
                        </g>
                        <defs>
                            <clipPath id="clip0_640_2044">
                                <rect
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                ></rect>
                            </clipPath>
                        </defs>
                    </svg>
                </button>
                <div className="w-full flex md:flex-row flex-col md:mb-8 mt-8 md:mt-0 justify-center items-center md:gap-8">
                    <p className="font-normal text-gray-400 text-left pt-2">
                        <span className="material-icons text-2xl align-middle">
                            email
                        </span>
                        <span className="pl-1 text-sm">
                            <span className="text-white">
                                Iykmediainbox@gmail.com
                            </span>
                        </span>
                    </p>
                    <p className="font-normal text-gray-400 text-left pt-2">
                        <span className="material-icons text-2xl align-middle">
                            phone
                        </span>
                        <span className="pl-1 text-sm">
                            <span className="text-white">
                                +234 813 656 5053
                            </span>
                        </span>
                    </p>
                </div>
            </div>
        </>
    );
};

export default App;
