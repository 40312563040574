import React, { ReactNode, useEffect, useRef } from "react";

interface InfiniteScrollComponentProps {
    children: ReactNode;
    speed?: "fast" | "slow" | "normal";
    direction?: "right" | "left";
}

const InfiniteScrollComponent: React.FC<InfiniteScrollComponentProps> = ({
    children,
    speed = "normal",
    direction = "right",
}: InfiniteScrollComponentProps) => {
    const scrollerRef = useRef(null);

    useEffect(() => {
        const scroller = scrollerRef.current as unknown as HTMLElement;
        // If a user hasn't opted in for reduced motion, then we add the animation
        if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
            addAnimation(scroller);
        }
    }, []);

    const addAnimation = (scroller: HTMLElement) => {
        const scrollerInner = scroller.querySelector(
            ".scroller__inner"
        ) as HTMLElement;

        if (scrollerInner) {
            // add data-animated="true" to every `.scroller` on the page
            scroller.setAttribute("data-animated", "true");

            // Make an array from the elements within `.scroller-inner`
            const scrollerContent = Array.from(
                scrollerInner.children
            ) as HTMLElement[];
            // For each item in the array, clone it
            // add aria-hidden to it
            // add it into the `.scroller-inner`
            scrollerContent.forEach((item) => {
                const duplicatedItem = item.cloneNode(true) as HTMLElement;
                duplicatedItem.setAttribute("aria-hidden", "true");
                scrollerInner.appendChild(duplicatedItem);
            });
        }
    };

    return (
        <div
            className="scroller"
            ref={scrollerRef}
            data-direction={direction}
            data-speed={speed}
        >
            <div className="scroller__inner">{children}</div>
        </div>
    );
};

export default InfiniteScrollComponent;
